import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "pinout"
    }}>{`Pinout`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "230.06134969325154%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Pinout",
          "title": "Pinout",
          "src": "/static/9989700f7a9d7328a636a149f94eb969/a6d36/niceview_pinout.png",
          "srcSet": ["/static/9989700f7a9d7328a636a149f94eb969/222b7/niceview_pinout.png 163w", "/static/9989700f7a9d7328a636a149f94eb969/ff46a/niceview_pinout.png 325w", "/static/9989700f7a9d7328a636a149f94eb969/a6d36/niceview_pinout.png 650w", "/static/9989700f7a9d7328a636a149f94eb969/e548f/niceview_pinout.png 975w", "/static/9989700f7a9d7328a636a149f94eb969/d0143/niceview_pinout.png 1025w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "dimensions"
    }}>{`Dimensions`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.07361963190185%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUklEQVQoz6WT4Y7CIBCE+/7PaKIVMDUK1C1bBHbhYter9cwll9z3q1kydBiGLsZ4v9+JqJSSf6d8Q0S11rbQAcDlcpnnGRHHcbx9My5Ya7334zg65y4L1wXnXK21a61tN1upC0TUWhNTZUMIgZm79g+eYmb+XIsxIuLnnJnFacfM0zQh4v2dlBIihhC2wxhjSmleeIhLKdZaRJymKaW0PRgtlHeIKOccQngGVmvFhZzzGtUnzCx6Ce91ZnEio9+Q8HPOzHy73YiokwBCCH8XExEAPK4qpaS1lpKI+NPwVvxmO6V0Op3kz/M8y8ZrVOtHzlkCZ+aU0jMwSUspZa0lohCCUsoYMwyD1lopNQwDABARMwOA917u9RUYIhpjEBEAdrvd8Xjc7/eHw6Hve6113/fX67W15r03xjjnpFSveq7FsNbKS3DOAYA8j/P5HGP8Uc8v/fVqmb2oz5kAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dimensions",
          "title": "Dimensions",
          "src": "/static/2f2592ff1ff8021932814b20cb76e3c4/a6d36/niceview_dimensions.png",
          "srcSet": ["/static/2f2592ff1ff8021932814b20cb76e3c4/222b7/niceview_dimensions.png 163w", "/static/2f2592ff1ff8021932814b20cb76e3c4/ff46a/niceview_dimensions.png 325w", "/static/2f2592ff1ff8021932814b20cb76e3c4/a6d36/niceview_dimensions.png 650w", "/static/2f2592ff1ff8021932814b20cb76e3c4/e548f/niceview_dimensions.png 975w", "/static/2f2592ff1ff8021932814b20cb76e3c4/3c492/niceview_dimensions.png 1300w", "/static/2f2592ff1ff8021932814b20cb76e3c4/821da/niceview_dimensions.png 2200w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "schematic"
    }}>{`Schematic`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.533742331288344%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABB0lEQVQoz42Qy2rDQAxF/f/f5G02WWfRpOAmJMbPeXhmrHlKU+zW4EALvoi7kDjoSkWKkRBTCIiYiSLAeL1G51IIRJS8xxgxRiLKOVPOtFMBjIHWuutAKTOMzpjh9mH6jj8eztrx86aEAClzzqZtvdZ7foEHxnjbCiF40ygLSvSzkryulQXdd6MUMGuv1PN0Uq9X3rTA3pjknOU8Wuu1Ts7pprFCwDAkxH6aAix9K+VXWcq6NiHAWkhU0HrNb5jV884T/bQzcF6VpXw+bUqz9xuMuMz/803R+/5yAcbeYtNh/fHtoyiirKqZsbdvH9wZYnycz/x+F84JgMlaRDy6OSEazgPAPvc3gdBGYe/NexEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Schematic",
          "title": "Schematic",
          "src": "/static/9d5c279767960b4d52206118d412fe18/a6d36/niceview_schematic.png",
          "srcSet": ["/static/9d5c279767960b4d52206118d412fe18/222b7/niceview_schematic.png 163w", "/static/9d5c279767960b4d52206118d412fe18/ff46a/niceview_schematic.png 325w", "/static/9d5c279767960b4d52206118d412fe18/a6d36/niceview_schematic.png 650w", "/static/9d5c279767960b4d52206118d412fe18/e548f/niceview_schematic.png 975w", "/static/9d5c279767960b4d52206118d412fe18/3c492/niceview_schematic.png 1300w", "/static/9d5c279767960b4d52206118d412fe18/9378a/niceview_schematic.png 3121w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      